module.exports = {
  siteTitle: 'Yannis Lam', // <title>
  manifestName: 'yannislam.org',
  manifestShortName: 'YL', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ff8b00',
  manifestThemeColor: '#ff8b00',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/logos/clearcolor.png',
  // pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Yannis Lam',
  subHeading: '',

  // social
  socialLinks: [
    {
      icon: 'far fa-envelope',
      name: 'Email',
      url: 'mailto:email@yannislam.org',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/yannis-lam/',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/AardeYamz',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://fb.com/AardeYamz',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/AardeYamz',
    },

  ],
  email: 'email@yannislam.org',
  // social
  
  contact: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/yannis-lam/',
      handle: '/in/yannis-lam/',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://fb.com/AardeYamz',
      handle: '/AardeYamz',
    },
    {
      icon: 'far fa-envelope',
      name: 'Email',
      url: 'mailto:email@yannislam.org',
      handle: 'email@yannislam.org',
    },
  ],

  education: [
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/3/35/University_of_Massachusetts_Amherst_seal.png',
      image_alt:'UMass Amherst',
      school: 'University of Massachusetts Amherst',
      more: 'Commonwealth Honors College - Computer Science (B.S) & Economics (B.A)',
      years:'Sep. 2017 - May 2021',
      gpa: '3.69',
      explanation: `I graduated with a dual degree in Computer Science and Economics at the University of Massachusetts Amherst Commonwealth Honors College in May 2021.
      Some advanced courses that I took were Physical Computing, Data Structures, Data Management, Web Programming, Digital Forensics, Internet Law, and Computer Networks for C.S. 
      I also took Political Economy of the Environment, Game Theory, Money and Banking, and Econometrics for Economics.`,
    },
    {
      img: 'https://images.squarespace-cdn.com/content/v1/5ef78c57895db7614a171f3c/1598618300431-0YXHJGM3IAMDO0NZBPO4/ke17ZwdGBToddI8pDm48kNezK55WTu4aDUFjMaT29wp7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UervjSGvnyjM2yCgODRWfyy97Tvxc_QK85A-Yr_Vt6iLbSexTd1-frD7527z4SM9QQ/BPS-LOGOcirlg.png',
      image_alt:'Braintree Public Schools',
      school: 'Braintree High School',
      more: 'High School Diploma - Summa Cum Laude',
      years:'Sep. 2013 - June 2017',
      gpa: '3.83',
      explanation: `Throughout high school, I completed 10 AP Courses and passed 10 AP tests while maintaining a 3.83 GPA and a class rank of 8/401. 
      I was an inducted member of the Edna M. Parker National Honor Society and acknowledged as a class scholar.`,
    },
  ],

  experience: [
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Voya_Financial_logo.png',
      link:'https://www.voya.com/',
      work: 'Voya Financial',
      title: 'IT Salesforce Development Intern',
      years:'May 2019 - Aug. 2020',
      about:`Voya Financial, Inc. (NYSE: VOYA), helps Americans plan, invest and protect their savings — to get ready to retire better. With a clear mission to make a secure financial future possible — one person, one family, one institution at a time — Voya’s vision is to be America’s Retirement Company®.`,
      explanation: `In the summers of 2019 and 2020, I worked as an IT Salesforce Development Intern at Voya Financial.
        During my time at Voya, I was tasked with improving the test coverage of Voya’s Retirement Salesforce
        environment. This included code refactoring and writing new unit tests for about 50 different Apex classes.
        I was responsible for achieving a higher level of automation in order to improve the quality of Voya’s
        Salesforce production environment for my other team members.
        Through my contributions to the team, I also developed a strong understanding of Apex, VisualForce, and
        SOQL as well as scrum and agile methodologies.`,
    },
    {
      img: 'https://pbs.twimg.com/profile_images/642029086622113792/R_xe0hTC_400x400.png',
      link:'https://www.umass.edu/living/',
      work: 'Residential Life',
      title: 'Peer Mentor',
      years: 'Jan. 2021 - May 2021',
      explanation: `I served as a Peer Mentor for Residential Life at UMass Amherst in the Gorman / Wheeler Residential Cluster. 
      For this job, I communicated with and advised a group of over 400 freshmen transitioning to on-campus dormitories during the Covid-19 emergency. 
      I also coordinated with other peer mentors and residential assistants to provide useful seminars for first year students.
      `,
    },
    {
      img: 'https://pbs.twimg.com/profile_images/905902678026354688/c_4AcKhd_400x400.jpg',
      link:'https://cics.umass.edu/',
      work: 'College of Computer & Information Sciences',
      title: 'Undergraduate Course Assistant - CS 345',
      years: 'Aug. 2020 - May 2021',
      explanation: `I served as an undergraduate course assistant to Instructor Liam Rothschild and Professor Jaime Dávila for 345: Practice and Applications of Data Management. 
      This class focused on data management through the use of SQL. As a UCA, I proctored weekly classes for 1 class section of 100 students, quickly graded assignments, and hosted weekly office hours on Zoom.
      I also helped with the development of new course material using SQL databases and Python autograders.`,
    },
    {
      title: 'Undergraduate Course Assistant - CICS 256 / 290M',
      years: 'Sep. 2019 - Dec. 2019',
      explanation: `I served as an undergraduate course assistant to Professors Rui Wang and Tauhidur Rahman for 256/290M Make: A Hand’s On Introduction to Physical Computing. 
      This class focused on lab-based physics, electronics, and Arduino programming. As a UCA, I proctored weekly labs for 1 class section of 35 students, quickly graded weekly lab reports for 2 sections totaling 75 students`,
    },
    {
      img: 'https://braintreema.gov/ImageRepository/Document?documentID=4780',
      link:'https://www.braintreeschools.org/',
      work: 'Braintree Public Schools',
      title: 'Substitute Technician & Collegiate Intern Technician',
      years:'Dec. 2016 - Present',
      explanation: `Based at Braintree High School, I helped service the entire school district as an intern.
      I have assisted in the deployment of Chromebooks, Mac OS updates, Windows 10 images, and Android tablets.
      My responsibilties included providing helpdesk assistence and maintaining an accurate inventory of the
      midsized enterprise network.
      While I started out as a High School Intern Technician, I was promoted to College Intern Technician and
      Substitute Technician.
      As a technician, I specialize in the repair of Chromebooks while mentoring teams of 15 high school
      interns.`, 
    },
    {
      img: 'https://cdn1-www.cru.org/epicmovement/wp-content/uploads/sites/39/2018/03/cropped-Artboard-1-1.png.pagespeed.ce.nOrh8-GSlT.png',
      link:'https://epicmovement.com/',
      work: 'Epic Movement at UMass Amherst',
      title: 'President',
      years: 'Aug. 2020 - May 2021',
      about: `Epic Movement is a Cru ministry that envisions Asian American students and faculty transformed by their relationship with Jesus Christ, 
      embracing the value of their cultures and actively engaging in God’s redemptive work in ways that honor all people and contexts. Epic Movement at UMass Amherst was established in the fall of 2008.`,
      explanation: `
      Beginning my senior year, I assumed the responsibilities of President. I led an Executive Board of 14 members as well as the general fellowship of 80 members during UMass Amherst's transition to remote learning.
      `,
    }, 
    {
      title: 'Event Coordinator & Photographer',
      years: 'Sep. 2018 - Aug. 2020',
      explanation: `During my sophomore and junior years at UMass, I served on the Executive Board for Epic Movement at UMass as the Event Coordinator and Photographer.
      As the Event Coordinator, I coordinated with UMass Administration to organize rooms for weekly meetings of 30 - 70 people. 
      My responsibilities also included organizing off campus events such as board game nights, hikes, and fundraisers. As the photographer, I helped expand UMass Epic Movement's social media presence on Facebook and Instagram.
      `,
    }, 
    {
      img: 'https://images.squarespace-cdn.com/content/5c7d8e4cc2ff6107242ff5f6/1556760518783-TJ3O5QM4FWX1FTQVVKGS/ARISElogoBMGrayFinal.png?format=1500w&content-type=image%2Fpng',
      link:'https://www.ariseyouth.org/',
      work: 'ARISE Youth Ministries',
      title: 'Youth Counselor',
      years: 'July 2018 - Aug. 2019',
      about: `ARISE is a para-church ministry dedicated to loving the Korean Church of New England primarily by serving the second-generation youth.`,
      explanation: `Throughout the period of a week, 
      I mentored a group of Korean American middle school boys at a retreat-style Christian camp. 
      I lead quiet time, Bible studies, and fellowship. I also shot raw footage that was later used in a retreat highlight video.`,
    }, 
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/TheHomeDepot.svg/1024px-TheHomeDepot.svg.png',
      link:'https://www.homedepot.com/',
      work: 'Home Depot',
      title: 'Customer Order Specialist',
      years: 'May 2018 - Sep. 2018',
      explanation: `At The Home Depot, I operated behind the service desk. 
      I assisted customers with purchases and returns - specializing with online orders. 
      I also answered confidential calls, restocked items, and kept the service desk a clean and presentable working environment.`,
    }, 
    {
      img: 'https://www.thayer.org/uploaded/themes/project_rise_18/images/Horizontal_W_Thayer_1.png',
      link: 'https://www.thayer.org/projectrise',
      work: 'Project RISE Boston',
      title: 'Math Teacher & Camp Counselor',
      years: 'May 2015 - Aug. 2016',
      about: `Project RISE (Respect, Integrity, and Success through Education) is 
      an academic enrichment program which runs both in the summer and during the school year focused on helping students of color succeed in the Greater Boston area.`,
      explanation: `
      Over the span of seven weeks, I taught mathematics and helped coordinate general camp activities for a group of 70 inner-city middle and high school students. I led five 50 minute math classes for 4 days a week in topics including arithmetic, algebra, geometry, and trigonometry. I also oversaw the creation of a new website: "projectriseboston.org" 
      (the website has since been updated) and produced a 5 minute video showing the perspective of a student at camp.`,
    }, 
  ],

  projects1: [
    {
      img: 'OLS Regression Plot.png',
      image_alt:'OLS Regression Plot',
      project: 'An Analysis of Regressions of Salary Factors in Massachusetts',
      time:'Senior Thesis - 2020 - 2021',
      explanation: `
      In 2020, city and state governments across the United States placed restrictions on different sectors of local
      economies, resulting in an economic recession with differing levels of unemployment and across states and
      industries. Employment salaries and rates varied significantly between remote white-collar workers and in
      person blue-collar workers. While Covid-19 has amplified the wage differentials between different industries,
      such differentials existed prior to the pandemic. Factors such as geographic location, company, occupation,
      industry, and work history all influence salaries. Previous research has focused on minimum wage increases,
      interstate comparison of manufacturing production, and work history to determine future jobs. 
      This thesis built models of previously collected employment data and analyzed the impact of variables in determining
      hourly salary. Linear, lasso, and ridge regression models were used to consider the differences. The models
      examined the causal relationships and predicted outcomes for occupations in the Commonwealth of Massachusetts.`,
      link:'',
    },
    {
      img: 'DBTBT.png',
      image_alt:'DBTBT',
      project: 'DBTBT.com',
      time:'Fall 2019',
      explanation: `In CS 326: Web Programming, I partnered with Christian Daher, Bhogesh Maddirala, and Matthew Lehoullier to create a website with the intent of helping people to make better financial decisions. Upon running a search on the website homepage (http://www.dbtbt.com/), the user will be presented with a number of items from Amazon.com, along with similarly-priced stock options. The idea is to encourage the user to invest their free money for the future, rather than wasting it on frivolous purchases. 
      This addresses a real world problem as many Americans do not make educated financial decisions. This project used Node.js (backend and auxiliary socket server),
      React (frontend), Apache, Nginx (reverse proxies),  Amazon EC2, GCP Compute Engine (hosting), Amazon RDS using MySQL (database), and Amazon Route 53 (DNS).      
      `,
      link:'https://github.com/cdaher3/DBTBT',
    },
    {
      img: '20191020_053757.jpg',
      image_alt:'Raspberry Pi Python Timelapse Controller',
      project: 'Raspberry Pi Python Timelapse Controller',
      time:'HackUMass 00110 - October 2019',
      explanation: `During the HackUMass VII competition, my team (Henry Cheung, Veronica Gusev, and Nhan Le) built a Raspberry Pi powered camera timelapse system. 
      The ardunio ran a python script that controlled a DSLR camera. This script took a time interval and sent it to the DSLR, received images were compiled and encoded into a video
      `,
      link:'https://github.com/dergoose/hackumass2019',
    },
    {
      img: 'IMG_6074.JPG',
      image_alt:'Blinds',
      project: 'Arduino Smart Window',
      time:'Fall 2018',
      explanation: `During the Fall 2018 semester, I took CICS 290M: MAKE: 
      A HANDS-ON INTRODUCTION TO PHYSICAL COMPUTING. In this class, I partnered with Jeffrey Carr 
      and we built an arduino powered smart window for our final project. The arduino had a photoresistor that would raise and lower a servo powered shade depending on the amount of light detected.`,
      link:'https://sites.google.com/umass.edu/smartblinds/about',
    },
    {
      img: 'IMG_1246.JPG',
      image_alt:'ZebRed PC Build',
      project: 'ZebRed PC Build',
      time:'December 2018',
      explanation: `Over the course of the past few years I have been perfecting my own desktop setup. Tired of using slugish prebuilt computers, I built my own!`,
      link:'https://pcpartpicker.com/user/Aardeyamz/saved/4yCnnQ',
    },
    {
      img: '20181013_141834.jpg',
      image_alt:'Blinds',
      project: 'Arduino Security System ',
      time:'HackUMass 00110 - October 2018',
      explanation: `During the HackUMass VI competition, my team (Jeffrey Carr, Henry Cheung, & Steven Yen) built an arduino powered alarm system. The alarm system had a distance proximity sensor that calculated based off of echo location. Based off of the distance readings, a neopixel ring would light up more pixels the closer an object was to the sensor. There was three levels of colors and if an object got too close, the neopixel ring would turn red.`,
      link:'https://github.com/jcarr98/HackUMass2018',
    },
  ],

  projects2: [
    {
      img: 'IMG_9104.JPG',
      image_alt:'NHD 2017',
      project: 'Queen of the Desert: Mother of Kings ',
      time:'NHD - Spring 2017',
      explanation: `During senior year of high school, my team (Matthew Pugsley, Katie O'Neil, Laura Wang, and myself) 
      competed in our final National History Day comptetition. 
      We researched Gertrude Bell and her legacy to current Middle Eastern affairs.
      `,
    },
    {
      img: 'IMG_20160311_201710.jpg',
      image_alt:'NHD 2016',
      project: 'Scientology: An Exploration into the Unknown',
      time:'NHD - Spring 2016',
      explanation: `In 2016, my team (Matthew Pugsley, Katie O'Neil, Laura Wang, and myself) researched the 
      life of L. Ron Hubbard and the rise of Scientology. 
      Our exhbit won 3rd place at the Massachusetts South Shore regional History Day competition.`,
    },
    {
      img: 'IMG_20150314_120402.jpg',
      image_alt:'Science Fair 2015',
      project: 'Finding MH370: How Satellite Data Was Used to Locate the Missing Malaysian Airliner',
      time:'Science Fair - Spring 2015',
      explanation: `Sophomore year of high school, I researched the disappearance of Malaysian Airlines flight MH370. 
      Using satelite data that was released to the public, I calculated a possible final path and coordinates of a 
      proposed crash site in the Indian Ocean. My project won 1st place at my high school science fair before I moved on to 
      regional and state science fairs.
      `,
    },
    {
      img: 'IMG_1787.JPG',
      image_alt:'NHD 2015',
      project: 'Clouded by Delusion: The Flawed Leadership & Legacy of the Salem Court System ',
      time:'NHD 2015 - Spring 2015',
      explanation: `For the National History Day contest of 2015, my team (Lily Tang, Matthew Pugsley, Amanda Ramos, and Caitlin Leach) researched and created an exhibit around the flawed court system that resulted in the Salem Witch Trials. 
      Our exhibit won 1st place at the the South Shore Regional competition and won an Honorable Mention at the Massachusetts History Day contest.`,
    },
  ],

  aardeyamz: [
    {
      title: 'Yannis Lam',
      language: 'Greek / Chinese / English',
      pronounciation: '/y æ - n ih s · l a m/',
      content: 
        [
          "what my parents decided to name me in English", 
          "my legal name in America", 
          `Yannis (Γιάννης) - Greek name meaning "God is gracious" or "with God's grace"; commonly translated to "John"`,
          `Lam (林) - Common British Hong Kong romanization of Chinese surname meaning "forrest"`
        ]
      ,
    },
    {
      title: '林恩力',
      language: 'Chinese',
      pronounciation: '/lam4 jan1 lik6/',
      content: 
        [
          `what my parents decided to name me in Chinese`,
          `what Chinese people call me`, 
          `林 - Common Chinese surname meaning "forest"; romanized to "Lam" or "Lin"`,
          `恩 - Chinese character meaning "grace"; common Chinese name for girls with the English name of "Grace"`,
          `力 - Chinese character meaning "force" or "strength"`
        ]
      ,
    },
    {
      title: 'Aarde · Yamz',
      language: 'Dutch / English',
      pronounciation: '/ardə jæm/',
      content: 
        [
          `what my friend Allison and I decided was a good username that no one had claimed in 2014`,
          `what people on the internet call me`,
          `Aarde - Dutch word meaning "earth"`,
          `Yamz - An abbreviation of my English name "Yannis"; what my friends called me in high school`
        ]
      ,
    },
  ],
};