import React from 'react';
import config from '../../config';
// import { Link } from 'gatsby';
// import Fade from 'react-reveal/Fade';
// import { Container } from 'react-bootstrap';
// import Title from '../components/SectionTitle';

export default function Footer() {
  var d = new Date();
  var year = d.getFullYear();
  return (
    <div>
      <section className="footer-section bg-black">
        <div className="arrow bounce">
          <a className="fa fa-angle-up fa-2x" href="#"></a>
        </div>
        <div className="social d-flex justify-content-center">
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <a key={url} href={url} className={`mx-2`}>
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div>
      </section>
      <footer className="bg-black text-center text-white-50">
        <div className="container">
          Copyright &copy; Yannis Lam {year}
      </div>
      </footer>
    </div>
  );
}
