import React, { Component } from 'react';
import Scroll from './Scroll';
// import config from '../../config';
import { Link } from 'gatsby';
import white from '../assets/images/logos/white.png';
import black from '../assets/images/logos/black.png';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
      logo: white,
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };
  handleScroll = () => {
    const { visibilityClass } = this.state;
    // if (window.pageYOffset > 300) {
    //   if (visibilityClass !== 'navbar-shrink') {
    //     this.setState({ visibilityClass: 'navbar-shrink', logo: black});
    //   }

    // } else {
    //   if (visibilityClass === 'navbar-shrink') {
    //     this.setState({ visibilityClass: '', logo: white });
    //   }
    // }

    if (window.pageYOffset > 300) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink', logo: black });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: '', logo: white });
      }
    }

  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { logo, openMenu, visibilityClass } = this.state;

    return (
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
        id="mainNav"
      >
        <div className="container">
          <a>
            <Link className="navbar-brand" to="/">
              <img src={`${logo}`} height="45" alt="YL" />
            </Link>
          </a>
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
              }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="about"
                >
                  <a>
                    <Link className="nav-link" to="/#about">
                      About
                    </Link>
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="projects"
                >
                  <a>
                    <Link className="nav-link" to="/projects">
                      Projects
                    </Link>
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="photography"
                >
                  <a>
                    <Link className="nav-link" to="/photography">
                      Photography
                      </Link>
                  </a>
                </Scroll>
              </li>
              {/* <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="blog"
                >
                  <a>
                    <Link className="nav-link" to="/blog">
                      Blog
                    </Link>
                  </a>
                </Scroll>
              </li> */}
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="contact"
                >
                  <a>
                    <Link className="nav-link" to="/contact">
                      Contact
                    </Link>
                  </a>
                </Scroll>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
